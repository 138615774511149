/* .carousel.carousel-slider {
  background: transparent;
  -webkit-tap-highlight-color: transparent;
}
.carousel.carousel-slider:focus {
  background: transparent !important;
  -webkit-tap-highlight-color: transparent;
} */
.control-arrow:focus {
  background: transparent;
  -webkit-tap-highlight-color: transparent;
}
.control-arrow:hover {
  background: rgba(0, 0, 0, 0.2) !important;
  -webkit-tap-highlight-color: transparent;
}
@media (pointer: none), (pointer: coarse) {
  .control-arrow:hover {
    -webkit-tap-highlight-color: transparent;
    background: transparent;
  }
}
.dot {
  width: 10px !important;
  height: 10px !important;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}
.control-dots {
  padding-bottom: 0px;
  padding-inline-start: 0px !important;
}

/* .control-arrow:active {
  background: transparent !important;
  -webkit-tap-highlight-color: transparent;
} */
